//
//
// pricing.scss
//
//

.pricing-table{
  border-bottom: $border-width solid $border-color;
  thead{
    th{
      border-top: 0;
      border-bottom-width: $border-width;
    }
  }
  th,td{
    padding: $spacer;
  }
  td,th{
    vertical-align: middle;
    .description {
      width: 40%;
    }
  }
  th, td{
    &:not(:last-child){
      border-right: $border-width solid $border-color;
    }
  }
  tbody{
    tr{
      transition: background-color $transition-time ease;
    }
  }

}

.pricing-table-competitors{
  thead{
    text-align: center;
    th{
      border-top: 0;
      vertical-align: middle;
    }
  }
  tbody{
    .icon-round{
      margin: 0 auto;
    }
    th{
      max-width: 120px;
    }
  }
}

.pricing-ticket{
  &:before, &:after{
    content: '';
    display: block;
    width: $spacer;
    height: $spacer;
    border-radius: 50%;
    background: $white;
    flex-shrink: 0;
    position: absolute;
    left: -$spacer/2;
  }
  &:after{
    left: auto;
    right: -$spacer/2;
  }
}


.pricing-plans {
  a.btn[data-cb-type="checkout"] {
    width: 90%;
    margin-top: 10px;
  }
}

