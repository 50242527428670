//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

//
//
// svg.scss
//
//

.icon-nofill {
  flex-shrink: 0;
  width: 20px;
  height: $spacer;
  &.icon-md{
    height: $spacer*1.5;
  }
  &.icon-lg {
    height: $spacer*2;
  }
  &.icon-sm {
    height: $spacer/2;
  }
}

.icon-nofill + .icon-nofill {
  margin-left: .25em;
}

.icon-nofill + .icon-nofill:last-child {
  margin-right: .25em;
}

.dropdown.language-selector {
  h6 {
    margin-top: 1.5em;
  }
}

img.logo {
  max-width: 100px;
}

.card img.logo {
  max-width: 100px;
  max-height: 50px;
}

.trusted-by {
  img {
    &.icon-logo {
      height: 30px;
    }
  }
}